<template>
  <div class="essential"  v-loading="loading">
      <p class="essential-title">基本信息</p>
      <div class="essential-center">
          <div class="essential-center-news">
                <div class="essential-list">
                    <p>企业名称</p>
                    <el-input v-model="sponsor.name" placeholder="请输入内容" v-if="companyInfo.status == 3"></el-input>
                    <span v-else>{{sponsor.name}}</span>
                </div>
                <div class="essential-list">
                    <p>企业地址</p>
                    <div v-if="companyInfo.status == 3">
                        <div class="address-center">
                            <el-select v-model="sponsor.provName" placeholder="请选择省" value-key="sponsor.provId" @change="onRegion">
                                <el-option v-for="item in areaList" :key="item.id" :label="item.name" :value="item.id" ></el-option>
                            </el-select>
                            <el-select v-model="sponsor.cityName" placeholder="请选择市" value-key="sponsor.cityId" @change="onCity">
                                <el-option v-for="item in cityList" :key="item.id" :label="item.name" :value="item.id"></el-option>
                            </el-select>
                            <el-select v-model="sponsor.districtName" placeholder="请选择区" value-key="sponsor.districtId" style="margin-right:0;" @change="onDistrict">
                                <el-option v-for="item in districtList" :key="item.id" :label="item.name" :value="item.id"></el-option>
                            </el-select>
                        </div>
                         <el-input v-model="sponsor.address" placeholder="请输入详细地址" style="width:100%;"></el-input>
                    </div>
                    <span v-else>{{sponsor.provName}} {{sponsor.cityName}} {{sponsor.districtName}} {{sponsor.address}}</span>
                </div>
                <div class="essential-list">
                  <p>信用代码</p>
                  <el-input v-model="sponsor.creditCode" placeholder="请输入信用代码" v-if="companyInfo.status == 3"></el-input>
                  <span v-else>{{sponsor.creditCode}}</span>
                </div>
          </div>
          <div class="essential-center-img">
              <p>营业执照</p>
              <div v-if="companyInfo.status == 3">
                <el-upload class="avatar-uploader" action="/" accept="image/jpeg,image/jpg,image/png" :show-file-list="false" ref="classImg" :http-request='submitUpload'>
                    <img v-if="sponsor.businessLicense" :src="sponsor.businessLicense" class="avatar">
                    <div v-else class="avater-tips">
                        <!-- <img src="../../assets/img/uoload.png" alt=""> -->
                        <!-- <svg-icon icon-class="upload"/> -->
                        <p>请上传</p>
                    </div>
                </el-upload>
              </div>
              <div v-else>
                  <el-popover placement="top-start" trigger="click">  <!--trigger属性值：hover、click、focus 和 manual-->
                    <a :href="sponsor.businessLicense" target="_blank" title="查看最大化图片">
                        <img :src="sponsor.businessLicense" style="width: auto;height: auto;">
                    </a>
                    <img slot="reference" :src="sponsor.businessLicense">
                </el-popover>
              </div>
          </div>
      </div>
      <div class="essential-button" v-if="companyInfo.status == 3"><button type="button" @click="onSubmit">重新提交</button></div>
  </div>
</template>

<script>
import { getSponsor } from "../../api/venue"
import { listArea } from "../../api/address"
import { upload } from "../../api/upload"
import { sendCode,updateCompany } from "../../api/register"
import { getCompanyInfo } from "../../api/personal"
export default {
    data(){
        return{
            sponsor:{},
            status:"",
            pid:100000,
            areaList:[],
            cityList:[],
            districtList:[],
            level: 1,
            loading: false,
            companyInfo:{}
        }
    },
    created(){
        this.getSponsor()
        this.status = localStorage.getItem('status')
        this.listArea(true)
        this.getCompanyInfo()
    },
    methods:{
        // 自定义的上传图片的方法
        async submitUpload({ file }) {
            this.loading = true
            const isLt2M = file.size / 1024 / 1024 < (this.maxSize || 1)
            if(!isLt2M) {
                this.$message.error(`大于${this.maxSize*1000 + this.formatBytes(this.maxSize*1024*1024)}图片不适合前台页显示，请重新压缩图片。`)
                return false
            }
            let formData = new FormData()
            formData.append('file', file, file.name)
            let res = await upload(formData)
            this.upload_url = res.data.data.url
            if (res.data.code == 20000) {
                // console.log(res)
                this.$emit('selectImg', {url:res.data.data.url })
                this.sponsor.businessLicense = res.data.data.url
                this.loading = false
            }
        },

        getCompanyInfo(){
            getCompanyInfo().then((res) => {
                if(res.data.code == 20000){
                    this.companyInfo = res.data.data.companyInfo
                }else{
                    this.$message.error(res.data.message)
                }
            })
        },

        getSponsor(){
            getSponsor().then((res) => {
                // console.log(res)
                this.sponsor = res.data.data.sponsor
            })
        },

        onRegion(value){
            console.log('省',value)
            this.sponsor.provId = value
            this.pid = value
            this.level = 2
            this.listArea()
        },

        onCity(value){
            // console.log('市',value)
            this.sponsor.cityId = value
            this.pid = value
            this.level = 3
            this.listArea()
        },

        onDistrict(value){
            // console.log('区',value)
            this.sponsor.districtId = value
        },

        //地区
        listArea(type){
            listArea({
                pid:this.pid
            }).then((res) => {
                if(res.data.code == 20000){
                    if (this.level == 1) {
                        this.areaList = res.data.data.areaList
                        if (type) {
                            this.level = 2
                            this.pid = res.data.data.areaList[0].id
                            this.listArea(type)
                        }
                    } else if (this.level == 2) {
                        this.cityList = res.data.data.areaList
                        if (type) {
                            this.level = 3
                            this.pid = res.data.data.areaList[0].id
                            this.listArea(type)
                        }
                    } else if (this.level == 3) {
                        this.districtList = res.data.data.areaList
                    }
                }
            })
        },

        //提交
        onSubmit(){
             if(this.sponsor.name == ""){
                this.$message.error('企业名称不能为空')
                return false
            }else if(this.sponsor.districtName == ""){
                this.$message.error('省市区不能为空')
                return false
            }else if(this.sponsor.address == ""){
                this.$message.error('企业详细地址不能为空')
                return false
            }else if(this.sponsor.creditCode == ""){
                this.$message.error('信用代码不能为空')
                return false
            }else if(this.sponsor.businessLicense == ""){
                this.$message.error('营业执照不能为空')
                return false
            }

            updateCompany(this.sponsor).then((res) => {
                if(res.data.code == 20000){
                    this.$message.success("您提交的信息将在一个工作日内审核通过，敬请关注")
                    this.$store.commit('del_token')
                    window.localStorage.clear(); 
                    this.$router.push("/login")
                }else if(res.data.code == 20033){
                    this.$message.error(res.data.message)
                    this.$router.push("/login")
                }else{
                    this.$message.error(res.data.message)
                }
            })
        }
    }
}
</script>

<style scoped lang="scss">
.essential{
    width:100%;
    height:592px;
    background-color:#FFFFFF;
    padding:30px;
    .essential-title{
        color:#000000;
        font-size:20px;
        font-weight:500;
    }
    .essential-center{
        width:100%;
        display:flex;
        align-items:center;
        margin-top:8px;
        .essential-center-news{
            width:465px;
            .essential-list{
                width:100%;
                // height:80px;
                padding:18px 0;
                border-bottom:1px solid #E9E9E9;
                display:flex;
                flex-direction: column;
                justify-content: center;
                p{
                    color:#999999;
                    font-size:14px;
                    padding-bottom:10px;
                }
                span{
                    color:#333333;
                    font-size:14px;
                }
            }
        }
        .essential-center-img{
            margin-left:50px;
            p{
                color:#999999;
                font-size:14px;
                padding-bottom:20px;
            }
            img{
                width:200px;
                height:142px;
                border-radius:3px;
            }
        }
    }
    .address-center{
        display:flex !important;
        align-items:center;
        justify-content: space-between !important;
        margin-bottom:15px;
        /deep/ .el-input{
            width:98%;
            margin-right:10px;
        }
        /deep/ .el-select{
            margin-right:8px;
        }
    }
    .essential-button{
        width:100%;
        display:flex;
        align-items:center;
        justify-content: flex-start;
        margin-bottom: 25px;
        margin-top: 40px;
        button{
            width: 111px;
            height: 40px;
            background: linear-gradient(270deg, #FF6100 0%, #FFB800 100%);
            border-radius: 6px;
            background-color:#FFFFFF;
            font-size:14px;
            display:flex;
            align-items:center;
            justify-content: center;
            color:#FFFFFF;
            font-size:14px;
            cursor: pointer;
            border:none;
        }
    }
}
</style>